import React from "react";
import Layout from "../components/Layout";
import Footer from "../components/Footer";
import SEO from "../components/Seo";
import Header from "../components/Header";
import Helmet from "react-helmet";

const StartPage = (props) => (
  <Layout location={props.location}>
    <SEO title="LifePlan - Life Insurance Application" />
    <Helmet>
      <meta name="robots" content="noindex" />
      <script src="https://storage.googleapis.com/niro-eu-static/widget/v1-2-3/index.min.js"></script>
    </Helmet>
    <div id="start-page" className="uk-container uk-padding-remove uk-flex">
      <div className="sitewrapper">
        <div id="hometopsection" className="uk-container hometopsection">
          <Header />
          <div className="container content">
            <div className="hometopdiv">
              <niroflow
                niroflow-id="lifeplan-application"
                width="100%"
                height="100%"
                pass-parameters="true"
                dynamic-height="true"
              ></niroflow>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </Layout>
);

export default StartPage;
