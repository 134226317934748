import React from "react";
import footer_location from "../images/footer-location.svg";
import footer_call from "../images/footer-call.svg";
import footer_time from "../images/footer-time.svg";
import header_linkedin from "../images/header-linkedin.svg";
import header_facebook from "../images/header-facebook.svg";
import header_instagram from "../images/header-instagram.svg";
import header_twitter from "../images/header-twitter.svg";
import header_youtube from "../images/header-youtube.svg";
import footer_arrow from "../images/footer-arrow.svg";

const Footer = () => (
  <div id="footersection" className="uk-container footersection">
    <div className="container content">
      <div className="footerdiv">
        <div className="uk-flex footermain">
          <div className="footercontact">
            <h2>Contact us</h2>
            <div className="uk-flex">
              <img src={footer_location} alt="location icon" />
              <p>3601 Hwy 7 East, Suite 610 Markham, Ontario L3R 0M3</p>
            </div>
            <div className="uk-flex">
              <img src={footer_call} alt="phone icon" />
              <p>905-294-PLAN(7526)</p>
            </div>
            <div className="uk-flex">
              <img src={footer_time} alt="clock icon" />
              <p>MON - FRI : 9:00 - 17:00</p>
            </div>
          </div>
          <div className="uk-flex uk-flex-column footerinfo">
            <div className="footerinfosupport">
              <h3>
                Customer support <img src={footer_arrow} alt="arrow" />
              </h3>
              <p>
                Contact Customer Support for questions on your products,
                planing, or events...
              </p>
            </div>
            <div className="footerinfosocial">
              <h3>Connect with LifePlan</h3>
              <div className="uk-flex">
                <img src={header_linkedin} alt="linkedin" />
                <img src={header_facebook} alt="facebook" />
                <img src={header_instagram} alt="instagram" />
                <img src={header_twitter} alt="twitter" />
                <img src={header_youtube} alt="youtube" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
